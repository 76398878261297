@font-face {
  font-family: 'TT Norms Pro';
  src: local('TT Norms Pro Bold'), local('TT-Norms-Pro-Bold'),
  url('/fonts/TTNormsPro-Bold.woff2') format('woff2'),
  url('/fonts/TTNormsPro-Bold.woff') format('woff'),
  url('/fonts/TTNormsPro-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'TT Norms Pro Regular';
  src: local('TT Norms Pro Regular'), local('TT-Norms-Pro-Regular'),
  url('/fonts/TTNormsPro-Regular.woff2') format('woff2'),
  url('/fonts/TTNormsPro-Regular.woff') format('woff'),
  url('/fonts/TTNormsPro-Regular.ttf') format('truetype');

  font-style: normal;
}

@font-face {
  font-family: 'TT Norms Pro Extra Bold';
  src: local('TT Norms Pro Extra Bold'), local('TT-Norms-Pro-Extra-Bold'),
  url('/fonts/TTNormsPro-ExtraBold.woff2') format('woff2'),
  url('/fonts/TTNormsPro-ExtraBold.woff') format('woff'),
  url('/fonts/TTNormsPro-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

* {
  font-family: TT Norms Pro;
  font-style: normal;
  font-weight: bold;
  color: #fff;
  margin: 0;
  padding: 0;
}

*:focus {
  outline: none;
  background-color: rgb(0,0,0,0);
}

html, body {
  height: 100%;
}

body {
  /*zoom: 150%;*/
  background: #5D596C;
  transform-origin: 0 0 ;
  overflow: hidden;
}



#wrapper {
  width: 1280rem;
  height: 720rem;
  background: #231F38;
  overflow: hidden;

  position: relative;

}

#splash-screen {
  width: 1280rem;
  height: 720rem;
  position: absolute;
  z-index: 0;
  background: #231F38;
}

.logo-big {
  background: url(/img/poleslov-ru.png);
  background-size: cover;
  width: 240rem;
  height: 120rem;
  position: absolute;
  left: 640rem;
  top: 360rem;
  margin-left: -120rem;
  margin-top: -60rem;
  z-index: 0;
}

.logo-big.uk {
  background: url(/img/poleslov-uk.png) no-repeat center;
  background-size: cover;
}

.logo-big.en {
  background: url(/img/poleslov-en.png) no-repeat center;
  background-size: cover;
}

.game {
  width: 1280rem;
  height: 720rem;
  z-index: 1;
  background: #231F38;
  position: absolute;
}

#top {
  height: 177rem;
  width: 1280rem;
  text-align: center;
  position: relative;

}

.focusable {
  cursor: pointer;
}

.menu-button {
  border: 5rem solid #666279;
  border-radius: 50%;
  color: #FB4236;
  width: 60rem;
  height: 60rem;
  position: absolute;
  top: 54rem;
  left: 54rem;
  background: url(/img/menu.png);
  background-size: cover;
}

.menu-button.focused{
  border-color: #fff;
  transition : border 0.3s;
  box-shadow:0 0 10rem #fff;
}






.header {
  height: 177rem;
  width: 100%;
  float: left;
}
.header.victory {
  float: right;
  width: 720rem;
}

.top-message.monostring {
  font-size: 50rem;
  margin-top: 54rem;
}

.top-message.doublestring {
  font-size: 32rem;
  margin-top: 45rem;
  font-family: "TT Norms Pro Regular";
  letter-spacing: 1rem;
  color: #C7C6CE;
}

.top-message.wrong {
  animation: lr-moving 0.5s ease-in-out;
}

.top-message.opponent-move:after {
  content: '.';
  animation: opponent-move 1s ease-out alternate infinite;
  margin-left: 5rem;
  text-shadow: 0.30em 0 0 transparent, 0.6em 0 0 transparent;
}

@keyframes opponent-move {
  65%  { text-shadow: 0.30em 0 0 #fff, 0.6em 0 0 transparent; }
  100% { text-shadow: 0.30em 0 0 #fff, 0.6em 0 0 #fff; }
}

.top-message.doublestring-winner {
  font-size: 45rem;
  margin-top: 35rem;
  font-family: "TT Norms Pro Regular";
  letter-spacing: 1rem;
  color: #FFF;
}

.word-bar {
  text-align: center;
}
.word-bar .message {
  height: 54rem;
  line-height: 54rem;
  font-size: 26rem;
  opacity: 0.4;
}

.word-bar .word-and-buttons {
  display: inline-block;
  position: relative;
}

.word-bar .word-and-buttons.wrong {
  animation: lr-moving 0.5s ease-in-out;
}

@keyframes lr-moving {
  0% {margin-left: 0rem;}
  25% {margin-left: -30rem;}
  50% {margin-left: 30rem;}
  75% {margin-left: -30rem;}
  100% {margin-left: 0rem;}
}

.word-bar .btn {
  border: 5rem solid #FB4236;
  color: #FB4236;
  width: 60rem;
  height: 60rem;
  border-radius: 50%;
  float: left;
  margin: 0 2rem;
}

.word-bar .btn.cancel {
  border-color: #FB4236;
  color: #FB4236;
  background: url(/img/clear.png);
  background-size: cover;
}

.word-bar .btn.ok {
  border-color: #FDBE45;
  background: url(/img/plus.png);
  background-size: cover;
}

.word-bar .btn.focused {
  border-color: #fff;
  transition : border 0.3s;
  box-shadow:0 0 10rem #fff;
}

.word-bar .score {
  position: absolute;
  left: 100%;
  margin-left: 10rem;
  bottom: 15rem;
  font-size: 40rem;
  line-height: 42rem;
}

.word-bar .word {
  float: left;
}

.word-bar .word.long {
  float: left;
  margin-top: 16rem;
}

.word-bar .word .letter{
  width: 70rem;
  height: 70rem;
  border-radius: 50%;
  float: left;
  margin: 0 2rem;
}

.word-bar .word.long .letter{
  width: 40rem;
  height: 40rem;
  border-radius: 50%;
  float: left;
  margin: 0 2rem;
}

.word .letter b{
  font-size: 34rem;
  line-height: 32rem;
  display: block;
  margin-top: 16rem;
  text-transform: capitalize;
}

.word.long .letter b{
  font-size: 22rem;
  line-height: 22rem;
  display: block;
  margin-top: 10rem;
  text-transform: capitalize;
}

.word .letter i {
  font-family: "TT Norms Pro Regular";
  font-size: 15rem;
  display: block;
  opacity: 0.3;
}

.word .letter.bc0 i,
.word .letter.bc1 i {
  opacity: 0.5;
}

.word.long .letter i{
  display: none;
}



#main {
  height: 543rem;
  width: 1280rem;
  /*overflow: hidden;*/
}

/* player-side */
.player-side {
  width: 314rem;
  padding-left: 50rem;
  padding-top: 23rem;
  position: relative;
  float: left;
  /*opacity: 0.3;
  animation: undoTurn 1s;*/
}

/*.player-side.turn {
  opacity: 1;
  animation: doTurn 1s;
}

@keyframes doTurn {
  0% {opacity: 0.3}
  100% {opacity: 1}
}
@keyframes undoTurn {
  0% {opacity: 1}
  100% {opacity: 0.3}
}*/


.player-side.right {
  padding-left: 0;
  padding-right: 50rem;
  text-align: right;
}
.player-side .light {
  background: url(/img/light-1.png);
  position: absolute;
  width: 278rem;
  height: 720rem;
  z-index: 10;
  right: 0;
  bottom: 0;
}

/* PERKS */
.player-side .perks {
  width: 60rem;
  background: #231E39;
  position: absolute;
  right: 0;
  z-index: 1;
}

.player-side.right .perks {
  left: 0;
}



.perk {
  width: 60rem;
  height: 60rem;
  background: #F95956;
  margin-bottom: 15rem;
  border-radius: 50%;
  position: relative;
  opacity: 0.5;

}



.perk.available {
  opacity: 1;
}

.perk .price {
  width: 26rem;
  height: 26rem;
  font-size: 12rem;
  font-family: "TT Norms Pro";
  line-height: 26rem;
  text-align: center;
  background: #8D97F2;
  border-radius: 50%;
  position: absolute;
  bottom: 0;
  right: -4rem;
  z-index: 15;
}

.perk.focusable:after {
  width: 50rem;
  height: 50rem;
  border-radius: 50%;
  border: 5rem solid #fff;
  box-shadow: 0 0 10rem #fff;
  content: " ";
  position: absolute;
  left: 0;
  opacity: 0;
}

.perk.focusable.focused:after{
  opacity: 1;
  transition: opacity .3s;
}

.perk.focusable.focused.clicked:after{
  opacity: 1;
  animation: focused-click 0.25s;
}

.perk.focusable.pressed:before {
  width: 56rem;
  height: 56rem;
  border-radius: 50%;
  border: 2rem solid #fff;
  content: " ";
  position: absolute;
  opacity: 0.4;
  left: 0;
}

.perk.block {
  background: #5E52A8 url(/img/perk-block-big.png);
  background-size: cover;
}

.perk.mutation {
  background: #364169 url(/img/perk-mutation-big.png);
  background-size: cover;
}

.perk.capture {
  background: #F05353 url(/img/perk-capture-big.png);
  background-size: cover;
}

.perk.moretime {
  background: #5E7483 url(/img/perk-moretime-big.png);
  background-size: cover;
}

.player-side .button-hint {
  position: absolute;
  width: 190rem;
  background: #645F7A;
  right: 90rem;
  padding: 20rem 25rem;
  border-radius: 30rem;
  z-index: 1;

}

.player-side.right .button-hint {
  right: unset;
  left: 90rem;
}

.button-hint .title {
  text-align: left;
  font-size: 20rem;
  color: #fff;
  letter-spacing: 1rem;
  margin-bottom: 10rem;
}

.button-hint .text {
  text-align: left;
  font-size: 16rem;
  color: #C7C6CE;
  font-family: "TT Norms Pro Regular";
  letter-spacing: 1rem;
}

/* SURRENDER */
.player-side .surrender {
  width: 60rem;
  height: 60rem;
  margin-bottom: 15rem;
  border-radius: 50%;
  position: absolute;
  right: 0;
  top: 395rem;
  background: #342F47 url(/img/surrender.png);
  background-size: cover;
}

.player-side.right .surrender {
  left: 0;
}

.surrender.focusable:after {
  width: 50rem;
  height: 50rem;
  border-radius: 50%;
  border: 5rem solid #fff;
  box-shadow: 0 0 10rem #fff;
  content: " ";
  position: absolute;
  left: 0;
  opacity: 0;
}

.surrender.focusable.focused:after{
  opacity: 1;
  transition: opacity .3s;
}
/* ------ */

.player-side .score {
  font-size: 80rem;
  line-height: 80rem;
  position: relative;
}

.player-side .score .message {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180rem;
  padding: 20rem;
  min-height: 60rem;
  border-radius: 28rem 28rem 28rem 0;
  background: #318EE4;
  font-size: 26rem;
  line-height: 30rem;
  font-family: "TT Norms Pro Regular";
  color: #FFFFFF;
  text-align: center;
  bottom: 35rem;
  opacity: 0;
  transition: all 300ms linear;
  transition-property: opacity, bottom;


}

.player-side .score .message.show {
  opacity: 1;
  bottom: 5rem;
  transition: all 300ms ease;
  transition-property: opacity, bottom;
}

/*.player-side .score .message:not(.show) {
  opacity: 0;
  bottom: 25rem;
  transition: all 300ms linear;
  transition-property: opacity, bottom;
}*/



/*@keyframes lightShow {
  0% {opacity: 0}
  100% {opacity: 1}
}
@keyframes lightHide {
  0% {opacity: 1}
  100% {opacity: 0}
}*/

.player-side.right .score .message {
  border-radius: 28rem 28rem 0 28rem;
  right: 0;
  background: #F65A5A;
}

.player-side .name-wr {
  width: 240rem;
  height: 40rem;
  position: relative;
}

.player-side.right .name-wr {
  margin-left: 74rem;
}

.player-side .name-wr .name {
  display: inline-block;
  float: left;
  font-size: 34rem;
  line-height: 40rem;
  max-width: 180rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.player-side.right .name {
  text-align: right;
  float: right;
}

.player-side .name-wr .badges-btn {
  float: left;
  margin: 0 7rem -5rem 7rem;
  width: 34rem;
  height: 34rem;
  border: 3rem solid #56526B;
  border-radius: 50%;
  top: 0;
  left: -34rem;
  background: url(/img/badges-btn-icon.png) #56526B;
  background-size: cover;
}

.player-side.right .name-wr .badges-btn {
  float: right;
  top: 0;
}


.player-side .badges-btn.focused {
  border-color: #fff;
  transition : border 0.3s;
  overflow: visible;
}

.player-side.right .network-status {
  font-size: 16rem;
  line-height: 20rem;
  width: 240rem;
  text-align: right;
  margin-left: 74rem;
  color: #33CC66;
  text-transform: uppercase;
  margin-top: 5rem;
}

.player-side.right .network-status.offline {
  color: #888888;
}


ul.words {
  font-size: 22rem;
  padding-inline-start: 0;
  margin-top: 90rem;
}
ul.words li {
  list-style-type:none;
  line-height: 31rem;
  margin-bottom: 10rem;
}

.middle {
  /*background: #bbbbbb;*/
  height: 543rem;
  width: 552rem;
  float: left;
}
.middle.victory {
  float: right;
  margin-right: 86rem;
}
#board {
  width: 488rem;
  height: 488rem;
  background: #342F47;
  margin: 0 auto;
  border-radius: 63rem;
  border-left: 4rem solid #318EE4;
  border-top: 4rem solid #342F47;
  border-right: 4rem solid #F65A5A;
  border-bottom: 4rem solid #342F47;
}



#board .letters {
  margin: 19rem;
}

#board .letter-wr {
  width: 90rem;
  height: 90rem;
  float: left;
  text-align: center;
  position: relative;
  overflow: visible;
}

#board .letter {
  width: 90rem;
  height: 90rem;
  background: #282c34;
  float: left;
  position: relative;
  text-align: center;
  z-index: 30;
  overflow: visible;
  text-transform: capitalize;
}

#board .letter.focusable.focused{
  z-index: 32;
}

#board .letter.focusable:after {
  width: 70rem;
  height: 70rem;
  border-radius: 50%;
  border: 5rem solid #fff;
  box-shadow:0 0 10rem #fff;
  content: " ";
  position: absolute;
  left: 5rem;
  top: 5rem;
  opacity: 0;
}

#board .letter.focusable.focused:after {
  opacity: 1;
  transition: opacity .3s;
}

#board .letter.focusable.focused.clicked:after {
  animation: focused-click 0.25s;
}



#board .letter.pressed:before {
  width: 76rem;
  height: 76rem;
  border-radius: 50%;
  border: 2rem solid #fff;
  content: " ";
  position: absolute;
  left: 5rem;
  top: 5rem;
  opacity: 0.4;
}
#board .letter .unavailable{
  position: absolute;
  width: 90rem;
  height: 90rem;
  top: 0;
  background: url(/img/unavalable-mask.png);
  background-size: cover;
}

#board .letter .show-perk {
  width: 26rem;
  height: 26rem;
  font-size: 11rem;
  font-family: "TT Norms Pro Regular";
  line-height: 26rem;
  text-align: center;
  position: absolute;
  bottom: 7rem;
  right: 7rem;
  z-index: 15;

}

/* Иконки перков при фокусе и при статичной привязке к полю */
#board.perk-selection-block .letter.focused .show-perk,
#board .letter .show-perk.block
{
  background: url(/img/perk-block-big.png) no-repeat;
  background-size: contain;
}

#board.perk-selection-mutation .letter.focused .show-perk,
#board .letter .show-perk.mutation
{
  background: url(/img/perk-mutation-big.png) no-repeat;
  background-size: contain;
}

#board.perk-selection-capture .letter.focused .show-perk,
#board .letter .show-perk.capture
{
  background: url(/img/perk-capture-big.png) no-repeat;
  background-size: contain;
}

#board .letter .uac{
  position: absolute;
  width: 45rem;
  height: 45rem;
  top: 0;
  position: absolute;
}
#board .letter .uac0{top: 0;      left: 0;            background: url("/img/unavalable-mask-c0.png"); background-size: cover;}
#board .letter .uac1{top: 0;      left: 45rem;        background: url("/img/unavalable-mask-c1.png"); background-size: cover;}
#board .letter .uac2{top: 45rem;  left: 45rem;        background: url("/img/unavalable-mask-c2.png"); background-size: cover;}
#board .letter .uac3{top: 45rem;  left: 0;            background: url("/img/unavalable-mask-c3.png"); background-size: cover;}

#board .letter b {
  font-size: 34rem;
  line-height: 32rem;
  display: block;
  margin-top: 27rem;
  color: #fff;
}

#board .letter.base0 b {
  color: #318EE4;
}

#board .letter.base1 b {
  color: #F65A5A;
}

#board .letter i {
  font-family: "TT Norms Pro Regular";
  font-size: 15rem;
  display: block;
  opacity: 0.3;
}

#board .letter.bc0 i,
#board .letter.bc1 i {
  opacity: 0.5;
}

.light {
  position: absolute;
  width: 1280rem;
  height: 720rem;
}

.light0 {
  position: absolute;
  width: 300rem;
  height: 720rem;
  left: 0;
  top: 0;
  background-image: url(/img/light-0.png);
  background-size: cover;
}

.light1 {
  position: absolute;
  width: 300rem;
  height: 720rem;
  right: 0;
  top: 0;
  background-image: url(/img/light-1.png);
  background-size: cover;
}

.light0.show,
.light1.show {
  opacity: 1;
  animation: lightShow 1s;
}

.light0.hide,
.light1.hide {
  opacity: 0;
  animation: lightHide 1s;
}

@keyframes lightShow {
  0% {opacity: 0}
  100% {opacity: 1}
}
@keyframes lightHide {
  0% {opacity: 1}
  100% {opacity: 0}
}

#kboard {
  position: absolute;
  width: 1280rem;
  height: 160rem;
  top: -160rem;
  background: #39335A;
  z-index: 30;
  text-align: center;
  text-transform: uppercase;
  /*display: none;*/
  transition-property: top;
  transition-duration: 0.5s;
}
#kboard.show {
  top: 0;
}

.kboard-row0 {
  margin: 30rem auto 0 53rem;
}
.kboard-row1 {
  margin: auto;
}
#kboard b {
  font-family: "TT Norms Pro Regular";
  width: 50rem;
  height: 50rem;
  display: inline-block;
  border-radius: 50%;
  background: #4d4769;
  position: relative;
  text-align: center;
  font-size: 22rem;
  line-height: 50rem;
  margin: 0 3rem;
}

#kboard b:after {
  width: 40rem;
  height: 40rem;
  border-radius: 50%;
  border: 5rem solid #fff;
  box-shadow:0 0 10rem #fff;
  content: " ";
  position: absolute;
  left: 0;
  opacity: 0;
}

#kboard b.focused:after {
  opacity: 1;
}

#kboard .down {
  background: url(/img/kboard-down.png);
  background-size: cover;
  display: inline-block;
  width: 27rem;
  height: 11rem;
  margin-top: 10rem;
}

.c0 {color: #318EE4 !important;}
.c1 {color: #F65A5A !important;}

.bc0 {background: #318EE4  !important;}
.bc1 {background: #F65A5A !important;}
.bc2 {background: #342F47 !important;}

.bc0na {background: #318EE4 url(/not-avalable-mask.png) !important;}
.bc1na {background: #F65A5A url(/not-avalable-mask.png) !important;}
.bc2na {background: #342F47 url(/not-avalable-mask.png) !important;}



/* safe side */
.ss {border-radius: 50%}
.ss0 {border-top-left-radius: 0;        border-top-right-radius: 0;}
.ss1 {border-top-right-radius: 0;       border-bottom-right-radius: 0;}
.ss2 {border-bottom-right-radius: 0;    border-bottom-left-radius: 0;}
.ss3 {border-bottom-left-radius: 0;     border-top-left-radius: 0;}

/* corner background*/
.cb {width: 45rem;   height: 45rem;   position: absolute; z-index: 20}
.cb0 {top: 0;       left: 0;}
.cb1 {top: 0;       left: 45rem;}
.cb2 {top: 45rem;    left: 45rem;}
.cb3 {top: 45rem;    left: 0;}

.confeti-wr {
  position: absolute;
}

.confeti {
  position: absolute;
  transition: top 5s linear;
  z-index: 60;
}

.confeti-wr.go .confeti {
  top: 2000rem !important;
}

.confeti.c1 {
  top: 200rem;
  width: 65rem;
  height: 60rem;
  background: url(/img/confeti/c1.png);
  background-size: cover;
}

.confeti.c2 {
  width: 70rem;
  height: 69rem;
  background: url(/img/confeti/c2.png);
  background-size: cover;
}

.confeti.c3 {
  width: 30rem;
  height: 31rem;
  background: url(/img/confeti/c3.png);
  background-size: cover;
}

.confeti.c4 {
  width: 24rem;
  height: 24rem;
  background: url(/img/confeti/c4.png);
  background-size: cover;
}

.confeti.c5 {
  width: 53rem;
  height: 53rem;
  background: url(/img/confeti/c5.png);
  background-size: cover;
}

.confeti.c6 {
  width: 39rem;
  height: 39rem;
  background: url(/img/confeti/c6.png);
  background-size: cover;
}

.confeti.c7 {
  width: 45rem;
  height: 52rem;
  background: url(/img/confeti/c7.png);
  background-size: cover;
}

@keyframes focused-click {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: .3;
  }
}



/*  LOADER  */

.loader {
  width:70rem;
  position: absolute;
  height: 70rem;
  z-index: 999;
}

.loader.corner {
  right: 50rem;
  top: 54rem;
}

.loader-big-wr {
  position: absolute;
  width: 1280rem;
  height: 720rem;
  background: #231F38;
  z-index: 80;
}

.loader-big {
  position: absolute;
  top: 325rem;
  left: 605rem;
}

.loader-popup {
  position: absolute;
  bottom: 70rem;
  right: 100rem;
  width: 70rem;
  height: 70rem;
}


@keyframes ldio-cfqkb8iew7j-o {
  0%    { opacity: 1; transform: translate(0 0) }
  49.99% { opacity: 1; transform: translate(20rem,0) }
  50%    { opacity: 0; transform: translate(20rem,0) }
  100%    { opacity: 0; transform: translate(0,0) }
}
@keyframes ldio-cfqkb8iew7j {
  0% { transform: translate(0,0) }
  50% { transform: translate(20rem,0) }
  100% { transform: translate(0,0) }
}
.ldio div {
  position: absolute;
  width: 20rem;
  height: 20rem;
  border-radius: 50%;
  top: 25rem;
  left: 15rem;
}
.ldio div:nth-child(1) {
  background: #318ee4;
  animation: ldio-cfqkb8iew7j 1s linear infinite;
  animation-delay: -0.5s;
}
.ldio div:nth-child(2) {
  background: #f65a5a;
  animation: ldio-cfqkb8iew7j 1s linear infinite;
  animation-delay: 0s;
}
.ldio div:nth-child(3) {
  background: #318ee4;
  animation: ldio-cfqkb8iew7j-o 1s linear infinite;
  animation-delay: -0.5s;
}
.loadingio-spinner-dual-ball {
  width: 70rem;
  height: 70rem;
  border-radius: 50%;
  background: #231E39;
  display: inline-block;
  overflow: hidden;
  position: absolute;
}

.ldio {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio div { box-sizing: content-box; }

/*=========*/
.enable-hardware-acceleration {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000rem;
}

/*==========================*/
.player-side .timer-frame {
  border: 4rem solid #fff;
  display: inline-block;
  padding: 2rem 0;

  min-width: 130rem;
  color: #ffffff;
  font-family: "TT Norms Pro Regular";
  font-weight: 600;
  font-size: 30rem;
  border-radius: 24rem;
  text-align: center;

  margin-bottom: 14rem;
}


.player-side .timer-frame.turn {
  background-color: #ffffff;
  color: #231F38;
}

.player-side .timer-frame.turn.low {
  color: #FB4236;
}

.player-side .timer {
  font-size: 30rem;
  width: auto;
}
