.game-times {
    position: absolute;
    top: 0;
    left: 0;
    width: 1280rem;
    height: 720rem;
    color:red;
    z-index: 70;
    background: #231F38;
}

.game-times .title {
    width: 1280rem;
    position: absolute;
    top: 80rem;
    font-family: "TT Norms Pro Extra Bold";
    font-size: 48rem;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
}

.game-times .subtitle {
    width: 1280rem;
    position: absolute;
    top: 150rem;
    font-family: "TT Norms Pro Regular";
    font-size: 30rem;
    font-weight: 600;
    text-align: center;
    color: #C7C6CE;
}

.game-times b {
    color: #fff;
}

.game-times .btn.close {
    border: 5rem solid #666279;
    border-radius: 35rem;
    color: #FB4236;
    width: 60rem;
    height: 60rem;
    position: absolute;
    top: 54rem;
    left: 54rem;
    background: url(/img/close.png);
    background-size: cover;
    z-index: 80;
}

.game-times .btn.close.focused{
    border-color: #fff;
    transition : border 0.3s;
    box-shadow:0 0 10rem #fff;
}

.game-times-items-list {
    position: absolute;
}

.game-times-item {
    position: relative;
    width: 440rem;
    height: 60rem;
    line-height: 60rem;
    background-color: #56526B;
    border: 5rem solid transparent;
    font-family: "TT Norms Pro Regular";
    font-weight: 600;
    font-size: 28rem;
    color: #ffffff;
    text-align: center;
    border-radius: 40rem;
    margin-bottom: 20rem;
}

.game-times-item.available:after {
    content: ' ';
    width: 15rem;
    height: 15rem;
    border-radius: 50%;
    display: block;
    position: absolute;
    background: #0FB986;
    top: 23rem;
    right: 25rem;
}

.game-times-item.focused {
    border: 5rem solid #ffffff;
    transition : border 0.3s;
    box-shadow:0 0 10rem #fff;
}
